module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-F7J5YWDFC6"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"yuichiro.__blog__","short_name":"__blog__","start_url":"/","background_color":"#574cf1","theme_color":"#574cf1","display":"minimal-ui","icon":"src/assets/images/blog_logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"21c6d8bcb070c29e04fdd5d385523b85"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
